import { LINK_LIST_LAYOUT_PARAMS } from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';

const { textAlignment } = LINK_LIST_LAYOUT_PARAMS;

export const getTextAlignment = (state) =>
  getAppSettingsValue({
    state,
    key: textAlignment.appSettingsPath,
    fallback: textAlignment.defaultAlignment,
  });
